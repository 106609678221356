<div class="d-flex justify-content-end" mat-dialog-title>
  <mat-icon class="pointer" mat-dialog-close>close</mat-icon>
</div>

<div class="py-2" mat-dialog-content>
  <div class="w-100 d-flex justify-content-center align-items-center flex-column">
    <img class="fut-emoji mb-1" src="../../../../../assets/emoji/swords.png" />
    <h1 class="fut-h1 mb-3">{{ 'dialogs.info_dialogs.shared_test_info.title' | transloco: { username: user_name } }}</h1>
    <h3 class="text-muted italic fut-h3 mb-4">{{ 'dialogs.info_dialogs.shared_test_info.subtitle' | transloco: { points: points } }}</h3>
  </div>

  <div class="w-100 bg-quaternary rounded p-4 prewrap">
    <h3 class="fut-h3 fut-bold mb-4">{{ data.test.name }}</h3>

    <div>
      <b>{{ questions_count }}</b> {{ 'dialogs.info_dialogs.shared_test_info.questions' | transloco }}
    </div>
    <div>
      <b>{{ duration }}</b> {{ 'dialogs.info_dialogs.shared_test_info.duration' | transloco }}
    </div>

    <div class="mt-4">{{ categories }}</div>
  </div>
</div>

<div class="d-flex justify-content-end align-items-center" mat-dialog-actions>
  <button class="fut-btn fut-btn-link mr-2 fut-bold" mat-dialog-close>{{ 'dialogs.info_dialogs.shared_test_info.reject' | transloco }}</button>
  <button class="fut-btn fut-btn-accent" mat-dialog-close="true">{{ 'dialogs.info_dialogs.shared_test_info.accept' | transloco }}</button>
</div>
