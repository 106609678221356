<div [matMenuTriggerFor]="helpMenu" [ngClass]="{ collapsed: collapsed() }" class="wrapper-icon">
  <mat-icon class="icon-sidenav" fontSet="material-symbols-outlined">help</mat-icon>
  <span class="title-sidenav">{{ 'sidenav.help' | transloco }}</span>
</div>

<mat-menu #helpMenu="matMenu">
  <ng-template matMenuContent>
    <fut-button-menu-content>
      <fut-button-menu-element (click)="reportIssue()" elementLabel="sidenav.help.report_issue" fontSet="material-symbols-outlined" icon="chat_error">
      </fut-button-menu-element>

      <fut-button-menu-element (click)="openAlice()" elementLabel="sidenav.help.alice" fontSet="material-symbols-outlined" icon="auto_awesome">
      </fut-button-menu-element>

      <fut-button-menu-element (click)="openGeneralSupport()" elementLabel="sidenav.help.support" fontSet="material-symbols-outlined" icon="help">
      </fut-button-menu-element>

      @if (!isMobileDevice()) {
        <fut-button-menu-element icon="info" fontSet="material-symbols-outlined" elementLabel="sidenav.help.tutorial" (click)="restartTutorial()">
        </fut-button-menu-element>
      }
    </fut-button-menu-content>
  </ng-template>
</mat-menu>
