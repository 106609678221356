<app-base-dialog-icon
  (closeDialog)="onCloseDialog()"
  [dialogIcon]="'/assets/emoji/brain.svg'"
  [dialogTitle]="'Valuta la tua conversazione'"
  [enableAutoClose]="false">
  <div class="w-100 d-flex justify-content-center align-items-center">
    <h3 class="fut-h3 text-muted">Aiutaci a migliorare Alice, come è stata la conversazione?</h3>
  </div>

  <div class="w-100 d-flex justify-content-between align-items-center mb-1 mt-4">
    <span>Inutile</span>
    <span>Molto utile</span>
  </div>

  <div class="w-100 d-flex justify-content-between align-items-center">
    @for (emoji of evaluationEmojis; track emoji; let i = $index) {
      <div class="emoji-container" [ngClass]="{ selected: evaluation === i }" (click)="evaluation = i">
        <img [src]="'/assets/emoji/' + emoji + '.svg'" width="30" />
      </div>
    }
  </div>

  @if (evaluation !== -1 && evaluation <= evaluationAskFeedback) {
    <mat-form-field appearance="outline" class="mt-4">
      <mat-label class="fut-bold">Ci dispiace, vuoi dirci altro?</mat-label>
      <textarea matInput type="text" [(ngModel)]="evaluationText"> </textarea>
    </mat-form-field>
  }

  <div class="d-flex justify-content-end align-items-center mt-2" style="gap: 0.5em" actions>
    <button (click)="onCloseDialog()" class="fut-btn fut-btn-link">Chiudi</button>
    <button (click)="onCloseDialog()" class="fut-btn fut-btn-accent">Invia</button>
  </div>
</app-base-dialog-icon>
