import { Component, output } from '@angular/core';
import { AliceUiService } from '../../service/alice-ui/alice-ui.service';
import { ConfigService } from 'src/app/core/config/config.service';
import { UserService } from 'src/app/core/user-service/user.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { UtilsService } from 'src/app/core/utils-service/utils.service';

const ALICE_PAGES_VISIBILITY = ['simulations', 'metrics', 'statistics', 'community', 'live', 'rec', 'archive', 'planner', 'profile'];

@Component({
  selector: 'app-alice-chat-button',
  templateUrl: './alice-chat-button.component.html',
  styleUrls: ['./alice-chat-button.component.scss'],
})
export class AliceChatButtonComponent {
  public isOpen = false;
  public showAliceButton = false;
  public showAlicePage = false;

  public readonly buttonVisibility = output<boolean>();

  constructor(
    private aliceUiService: AliceUiService,
    private configService: ConfigService,
    private userService: UserService,
    private router: Router,
    private utilService: UtilsService
  ) {
    const baseShowAliceButton = this.configService.showAliceV2;
    const alice = this.configService.aliceLabels;

    if (!alice || !alice.lables) {
      this.showAliceButton = baseShowAliceButton;
      return;
    }

    this.userService.getLabel().subscribe(user_labels => {
      this.showAliceButton = baseShowAliceButton && this.userService.checkLables(user_labels, alice?.lables, alice?.whitelist);
    });

    this.checkPage(location.pathname);

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(event => event as NavigationEnd)
      )
      .subscribe((events: NavigationEnd) => {
        this.checkPage(events.url);
      });
  }

  public open(): void {
    this.isOpen = true;

    const chatButton = this.aliceUiService.open();

    chatButton.afterOpened().subscribe(() => this.utilService.sendEvent('action_gpt_v2_chat_start'));
    chatButton.afterDismissed().subscribe(() => (this.isOpen = false));
  }

  public checkPage(page: string): void {
    if (page.startsWith('/')) page = page.slice(1);
    this.showAlicePage = ALICE_PAGES_VISIBILITY.includes(page);
    this.buttonVisibility.emit(this.showAliceButton && this.showAlicePage);
  }
}
