import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AliceEvaluationComponent } from './components/alice-evaluation/alice-evaluation.component';
import { AliceV2ChatComponent } from './components/alice-v2-chat/alice-v2-chat.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { AliceChatButtonComponent } from './components/alice-chat-button/alice-chat-button.component';
import { BaseDialogComponent } from '../dialogs/base-dialog/base-dialog.component';
import { BaseDialogIconComponent } from '../dialogs/base-dialog-icon/base-dialog-icon.component';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { GhostFragmentComponent } from '@futura/futura-ui/ghost';

@NgModule({
  declarations: [AliceEvaluationComponent, AliceV2ChatComponent, AliceChatButtonComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatDialogModule,
    FormsModule,
    TranslocoModule,
    BaseDialogComponent,
    BaseDialogIconComponent,
    MatFormField,
    MatInput,
    MatLabel,
    GhostFragmentComponent,
  ],
  exports: [AliceV2ChatComponent, AliceChatButtonComponent],
})
export class AliceV2Module {}
