import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Settings, SettingsContent } from '../model/settings.model';
import { CacheBuilder } from '../util/cache.util';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private readonly cache = CacheBuilder.persistence<keyof SettingsContent, SettingsContent[keyof SettingsContent]>()
    .setExpireTime(1_000 * 60 * 60 * 24)
    .build();

  constructor(private http: HttpClient) {}

  public getSettings(): Observable<Settings> {
    return this.http.get<Settings>(environment.be_url + '/settings/');
  }

  public readSetting<K extends keyof SettingsContent>(setting: K): Observable<SettingsContent[K]> {
    return this.cache.get(setting, () => this.getSetting(setting));
  }

  public writeSetting<K extends keyof SettingsContent>(setting: K, value: SettingsContent[K]): Observable<SettingsContent[K]> {
    return this.updateSetting(setting, value).pipe(switchMap(newSettings => this.cache.update(setting, newSettings)));
  }

  private getSetting<K extends keyof SettingsContent>(setting: K): Observable<SettingsContent[K]> {
    return this.http.get<SettingsContent[K]>(environment.be_url + `/settings/${setting}`);
  }

  private updateSetting<K extends keyof SettingsContent>(setting: K, newSettings: SettingsContent[K]): Observable<SettingsContent[K]> {
    return this.http.post<SettingsContent[K]>(environment.be_url + `/settings/${setting}`, newSettings);
  }
}
