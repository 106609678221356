<div class="main-container auth-container">
  <div class="background"></div>
  <div class="container-fluid h-100">
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-12 col-lg-10 h-100">
        <div class="row h-100">
          <div class="col-1"></div>
          <div class="col-12 col-lg-5 h-auto h-lg-100">
            <div class="w-100 h-100 d-flex align-items-center justify-content-center justify-content-lg-end mr-0 mr-lg-5 pr-0 pr-lg-2">
              <div class="w-100 d-flex flex-column align-items-center">
                <div *transloco="let translate" class="auth-card">
                  <h3 [futEditable]="'auth.login.sidebox.title'" class="fut-h3">
                    {{ translate('auth.login.sidebox.title') }}
                  </h3>
                  <span [futEditable]="'auth.login.sidebox.subtitle'" class="d-block d-lg-none">
                    {{ translate('auth.login.sidebox.subtitle') }}
                  </span>
                  <div class="features-list">
                    @for (auth_point of AUTH_POINT_LIST; track auth_point) {
                      <div class="d-flex">
                        <mat-icon>check</mat-icon>
                        <span [futEditable]="'auth.list.points-' + auth_point" [innerHTML]="translate('auth.list.points-' + auth_point)"></span>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-1 d-none d-lg-block"></div>
          <div class="col-12 col-lg-4 h-auto h-lg-100">
            <div class="w-100 h-100 d-flex align-items-start align-items-lg-center justify-content-center justify-content-lg-start">
              <div class="fut-card">
                <div class="d-flex flex-column w-100">
                  <ng-container *transloco="let translate">
                    <div class="d-flex flex-column align-items-center">
                      <div class="mb-2 fut-logo">
                        <img [src]="authLogo || '../../../../assets/futura-logo-blu.svg'" alt="Futura" />
                      </div>
                      <h2 class="mb-5 text-center" futEditable="auth.login.title" style="max-width: 17em">{{ translate('auth.login.title') }}</h2>
                    </div>
                    <h3 [futEditable]="'auth.login.form.title'" class="fut-h3 mb-0">{{ translate('auth.login.form.title') }}</h3>
                    <span [futEditable]="'auth.login.form.subtitle'" class="mb-4">{{ translate('auth.login.form.subtitle') }}</span>
                  </ng-container>
                  <form (submit)="login()" [formGroup]="phoneForm" class="w-100 d-flex flex-column">
                    <app-phone-input formControlName="phone" placeholder="Numero di telefono" [showErrorMessage]="false"></app-phone-input>
                    <span [futEditable]="'auth.login.form.otp_explanation'" class="fut-small mt-3 mb-3">{{
                      'auth.login.form.otp_explanation' | transloco
                    }}</span>
                    <button class="fut-btn fut-btn-accent mt-4 w-100" id="submit-button" tabindex="0" type="submit">
                      {{ 'auth.login.form.submit' | transloco }}
                    </button>
                    <span (click)="privacyPolicy()" [futEditable]="'auth.login.privacy_policy'" class="mt-3 fut-small pointer">{{
                      'auth.login.privacy_policy' | transloco
                    }}</span>
                  </form>
                </div>
                <div class="auth-footer-info"></div>
              </div>
            </div>
          </div>
          <div class="col-1"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="auth-footer">
    <div class="row h-100">
      <div class="col-2 d-none d-lg-block"></div>
      <div class="col-4 d-none d-lg-block"></div>
      <div class="col-12 col-lg-3">
        <div *transloco="let translate" class="d-flex justify-content-center justify-content-lg-end align-items-center h-100" style="gap: 1em">
          <span (click)="goAboutUs()" class="pointer nowrap">{{ translate('auth.login.footer.about_us') }}</span>
          <span (click)="goPlatform()" class="pointer nowrap">{{ translate('auth.login.footer.platform') }}</span>
          <span (click)="goCourses()" class="pointer nowrap">{{ translate('auth.login.footer.courses') }}</span>
        </div>
      </div>
      <div class="col-3 d-none d-lg-block"></div>
    </div>
  </div>
</div>
